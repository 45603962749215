import React, { useState } from 'react';
import {
  useLocale,
  Edit,
  TextInput,
  DateInput,
  SimpleForm,
  FormDataConsumer,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import { QRCode } from 'react-qrcode-logo';
import { Box, Button, Typography } from '@material-ui/core';

const AppcodeEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button m={5} onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const AppcodeEdit = ({ onCancel, ...props }) => {
  const locale = useLocale();
  const [qrType, setQrType] = useState(1);

  const changeQrType = () => {
    if (qrType < 3) {
      setQrType(qrType + 1);
    } else {
      setQrType(1);
    }
  };

  const generateQrCode = (invitationUrl) => (
    <Box onClick={changeQrType}>
      {qrType === 1 && (
        <QRCode
          value={invitationUrl}
          size={260}
          logoImage="logo192.png"
          logoWidth={106}
          logoHeight={106}
          logoOpacity={0.3}
          fgColor="#82368c"
          qrStyle="dots"
          eyeRadius={3}
        />
      )}
      {qrType === 2 && (
        <QRCode
          value={invitationUrl}
          size={260}
          logoImage="images/qr-logo.png"
          logoWidth={40}
          logoHeight={40}
          logoOpacity={0.75}
          fgColor="#82368c"
          qrStyle="dots"
          eyeRadius={3}
        />
      )}
      {qrType === 3 && (
        <QRCode
          value={invitationUrl}
          size={260}
          logoImage="images/qr-logo.png"
          logoWidth={40}
          logoHeight={40}
          logoOpacity={0.9}
        />
      )}
    </Box>
  );

  return (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<AppcodeEditToolbar onCancel={onCancel} />}>
      <TextInput source="id" disabled />
      <TextInput source="name" validate={required()} />
      <TextInput source="code" validate={required()} />
      <DateInput source="validFrom" locales={locale} />
      <DateInput source="validTo" locales={locale} />

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const appRedirectUrl = `${process.env.REACT_APP_FIREBASE_APP_URL}`;
          const params = { referrer: formData.code };
          const referrerParams = encodeURIComponent(JSON.stringify(params));
          const urlParams = encodeURIComponent(`https://app.getbloomup.com/login?referrer=${referrerParams}`);
          const linkParam = `&link=${urlParams}`;

          const invitationUrl = `${appRedirectUrl}${linkParam}`;

          return (
            <div style={{ float: 'right', position: 'absolute', top: '92px', left: '550px' }}>
              <a href="{invitationUrl}" style={{ display: 'block', marginBottom: '15px', marginLeft: '10px' }}>
                <Typography variant="caption">{invitationUrl}</Typography>
              </a>
              {generateQrCode(invitationUrl)}
            </div>
          );
        }}
      </FormDataConsumer>


    </SimpleForm>
  </Edit>
)};

export default AppcodeEdit;
