import React, { forwardRef } from 'react';
import { usePermissions } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import IconDashboard from '@material-ui/icons/Dashboard';
import IconShoppingCart from '@material-ui/icons/ShoppingCart';
import IconPeople from '@material-ui/icons/People';
import IconBarChart from '@material-ui/icons/BarChart';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';

import UserIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PagesIcon from '@material-ui/icons/MenuBook';
import ContentIcon from '@material-ui/icons/DynamicFeed';
import CodesIcon from '@material-ui/icons/BorderOuter';
import GamesIcon from '@material-ui/icons/SportsEsports';
import PaymentIcon from '@material-ui/icons//Payment';
import FitIcon from '@material-ui/icons/FitnessCenter';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import AssessmentIcon from '@material-ui/icons/BarChart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { NavLink, NavLinkProps } from 'react-router-dom';

const AppMenu: React.FC = () => {
  const { permissions } = usePermissions();

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {permissions?.includes('seeDashboard') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <IconDashboard />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem> : null}

      {permissions?.includes('manageSettings') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/settings'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem> : null}

      {/*permissions?.includes('manageSettings') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/pages'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <PagesIcon />
        </ListItemIcon>
        <ListItemText primary="Pages" />
      </ListItem> : null*/}

      {permissions?.includes('manageUsers') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/users'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <UserIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem> : null}
  
      {permissions?.includes('manageAppcodes') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/appcodes'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <CodesIcon />
        </ListItemIcon>
        <ListItemText primary="Appcodes" />
      </ListItem> : null}

      {permissions?.includes('manageGamification') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/rewards/settings'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <GamesIcon />
        </ListItemIcon>
        <ListItemText primary="Gamification" />
      </ListItem> : null}

      {permissions?.includes('manageFitContent') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/fit'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <FitIcon />
        </ListItemIcon>
        <ListItemText primary="BloomFit" />
      </ListItem> : null}

      {permissions?.includes('manageQuotes') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/quotes/admin'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <QuoteIcon />
        </ListItemIcon>
        <ListItemText primary="Zitate" />
      </ListItem> : null}

      {permissions?.includes('manageNotifications') ? <>
        <ListItem button onClick={handleClick} className={classes.menuItem}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ContentIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
          {open ? <IconExpandLess /> : <IconExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
          {permissions?.includes('managePromotions') ? <ListItem
              button
              className={classes.menuItem}
              component={forwardRef((props, ref) => <NavLink to={`/notifications?displayedFilters=%7B%7D&filter=%7B"category"%3A"PROMOTION"%7D`} exact {...props} innerRef={ref} />)}
            >
              <ListItemText inset primary="Promotions"  />
            </ListItem> : null}

            <ListItem
              button
              className={classes.menuItem}
              component={forwardRef((props, ref) => <NavLink to={`/notifications?displayedFilters=%7B%7D&filter=%7B"category"%3A"NOTIFICATION"%7D`} exact {...props} innerRef={ref} />)}
            >
              <ListItemText inset primary="Benachrichtigungen" />
            </ListItem>

            {permissions?.includes('manageEvaluationMessages') ? <ListItem
              button
              className={classes.menuItem}
              component={forwardRef((props, ref) => <NavLink to={`/notifications?displayedFilters=%7B%7D&filter=%7B"category"%3A"EVALUATION"%7D`} exact {...props} innerRef={ref} />)}
            >
              <ListItemText inset primary="Testzeitraum" />
            </ListItem> : null}

            {permissions?.includes('manageGamificationMessages') ? <ListItem
              button
              className={classes.menuItem}
              component={forwardRef((props, ref) => <NavLink to={`/notifications?displayedFilters=%7B%7D&filter=%7B"category"%3A"GAMIFICATION"%7D`} exact {...props} innerRef={ref} />)}
            >
              <ListItemText inset primary="Gamification" />
            </ListItem> : null}
          </List>
        </Collapse>
      </> : null}

      {permissions?.includes('getAssessment') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/assessment'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Auswertung" />
      </ListItem> : null}

      {permissions?.includes('managePayments') ? <ListItem
        button
        className={classes.menuItem}
        component={forwardRef((props, ref) => <NavLink exact {...props} innerRef={ref} />)}
        to={'/payments'}
      >
        <ListItemIcon className={classes.menuItemIcon}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Belege" />
      </ListItem> : null}

    </List>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      //color: '#97c05c',
    },
  }),
)

export default AppMenu
