import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, makeStyles } from '@material-ui/core';
import {
  List,
  EditButton,
  DeleteButton,
  Datagrid,
  CardActions,
  CreateButton,
  FunctionField,
  TextField,
} from 'react-admin';
import { Route } from 'react-router';
import GamificationCreate from './GamificationCreate';
import GamificationEdit from './GamificationEdit';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const GamificationListActions = ({ basePath }) => (
   <CardActions>
       <CreateButton basePath={basePath} />
   </CardActions>
);

const GamificationList = props => {
  const classes = useStyles();

  const handleClose = () => {
    props.push("/gamification");
  };

  return (
    <React.Fragment>
      <List {...props} actions={<GamificationListActions />} perPage={25}>
        <Datagrid>
          <TextField source="group" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="value" />
          <EditButton basePath="/rewards/settings" />
        </Datagrid>
      </List>

      <Route path="/gamification/create">
        {({ match }) => {
          //console.log('CREATE?')
          return;
          return (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <GamificationCreate onCancel={handleClose} className={classes.drawerContent} {...props} />
            </Drawer>
          )
        }}
      </Route>
      <Route path="/gamification/update/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <GamificationEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { push }
)(GamificationList);


