import React, { useEffect, useParams } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, Button, makeStyles } from '@material-ui/core';
import {
  useLocale,
  useRecordContext,
  List,
  EditButton,
  DeleteButton,
  Datagrid,
  CardActions,
  CreateButton,
  SelectInput,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
  FormDataConsumer,
  TopToolbar,
} from 'react-admin';
import { Route } from 'react-router';
import NotificationCreate from './NotificationCreate';
import NotificationEdit from './NotificationEdit';
import DeeplinkIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const NotificationListActions = ({ basePath }) => (
  <TopToolbar>
    <Button
      variant="outlined"
      startIcon={<DeeplinkIcon />}
      size="small"
      style={{ marginRight: 50 }}
      onClick={() => { window.open(`https://github.com/kodira/bloomup/wiki/BloomUp-Spec-(WIP)#deep-links`, '_blank') }}
    >
      Deep-Links
    </Button>

    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const NotificationList = props => {
  const locale = useLocale();
  const classes = useStyles();

  const handleClose = () => {
    props.push("/notifications/quotes");
  };

  const notificationCategories = [
    { item: 'PROMOTION', label: 'Promotions' },
    { item: 'NOTIFICATION', label: 'Benachrichtigungen' },
    { item: 'EVALUATION', label: 'Testzeitraum' },
    { item: 'GAMIFICATION', label: 'Gamification' },
  ];

  const notificationFilters = [
    <SelectInput alwaysOn source="category" choices={notificationCategories} optionValue="item" optionText="label" />,
  ];

  const selectedFilters = notificationCategories.map((category) => props.location.search.includes(category.item) ? category.item : null).filter(x => x);

  const showValidTimespanColumns = selectedFilters.includes('PROMOTION') || selectedFilters.includes('NOTIFICATION');
  const showEvaluationColumns = selectedFilters.includes('EVALUATION');
  const showPointsColumn = selectedFilters.includes('GAMIFICATION');
  const showNotifyColumn = true;
  const showUrlColumn = !selectedFilters.includes('GAMIFICATION');

  return (
    <React.Fragment>
      <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={notificationFilters} filterDefaultValues={{ category: 'QUOTE' }} actions={<NotificationListActions />} perPage={25}>
        <Datagrid>
          <TextField source="category" />
          <TextField source="title" />

          {showUrlColumn && <TextField source="url" />}

          {showValidTimespanColumns && <DateField source="validFrom" locales={locale} />}
          {showValidTimespanColumns && <DateField source="validUntil" locales={locale} />}

          {showEvaluationColumns && <FunctionField label="Tageszahl" source="validFrom" render={record => (new Date(record.validFrom)).getDate()} />}
          {showEvaluationColumns && <BooleanField source="showAfterEvaluationPeriod" />}

          {showPointsColumn && <TextField source="pointThreshold" />}

          {showNotifyColumn && <BooleanField source="notify" label="Smartphone-Notification" />}

          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>

      <Route path="/notifications/create">
        {({ match }) => {
          return;
          return (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <NotificationCreate onCancel={handleClose} className={classes.drawerContent} {...props} />
            </Drawer>
          )
        }}
      </Route>
      <Route path="/notifications/update/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <NotificationEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { push }
)(NotificationList);


