import React from 'react';
import { Edit, SimpleForm, Toolbar, SaveButton, TextInput, BooleanInput } from 'react-admin';

const PageEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const PageEdit = (props) => {
  return (
    <Edit title="Edit page" {...props}>
      <SimpleForm toolbar={<PageEditToolbar />}>
        <TextInput source="id" disabled />
        <TextInput source="title" format={v => v ? v.rendered : null} parse={v => ({ rendered: v })} />
        <TextInput multiline source="content" rows="10" format={v => v ? v.rendered : null} parse={v => ({ rendered: v })} />
        <BooleanInput source="status" label="Veröffentlicht" />
      </SimpleForm>
    </Edit>
  );
};

export default PageEdit;
