import React, { useState } from 'react';
import {
  Edit,
  TextInput,
  DateInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import { Box, Button } from '@material-ui/core';
import { useLocale } from 'react-admin';

const FitEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button m={5} onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const FitEdit = ({ onCancel, ...props }) => {
  const locale = useLocale();

  return (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<FitEditToolbar onCancel={onCancel} />}>
      <TextInput source="id" disabled />
      <TextInput source="category" validate={required()} />
      <TextInput source="title" validate={required()} />
      <TextInput source="url" validate={required()} />
    </SimpleForm>
  </Edit>
)};

export default FitEdit;
