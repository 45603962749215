import React from 'react';
import {
  useLocale,
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  EditButton,
  DeleteWithConfirmButton,
  ReferenceField,
} from 'react-admin';

const UserList = (props) => {
  const locale = useLocale();

  const userFilters = [
    <TextInput label="Uuid" source="uuid" alwaysOn />,
    <TextInput label="Name" source="name" alwaysOn />,
    <TextInput label="Email" source="email" alwaysOn />,
    <TextInput label="Referrer-ID" source="referrer" alwaysOn />,
    <TextInput label="Referrer" source="referredBy" alwaysOn />,
    <TextInput label="Appcode" source="appcode" alwaysOn />,
  ];

  return (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={userFilters}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="uuid" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="referrer" label={<nobr>Referrer-ID</nobr>} />
        <ReferenceField label="Referrer" source="referredBy" reference="users">
          <TextField source="referrer" />
        </ReferenceField>
        <TextField source="appcode" />
        <TextField source="role" />
        <DateField source="createdAt" showTime locales={locale} />
        <TextField source="isEmailVerified" />
        <TextField source="subscribed" />
        <EditButton basePath="/users" />
        <DeleteWithConfirmButton basePath="/users" undoable={false} label="User + Einträge löschen" />
      </Datagrid>
    </List>
  );
};

export default UserList;
