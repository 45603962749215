import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useDataProvider, useGetIdentity, Loading, Error } from 'react-admin';
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Dot,
  ReferenceLine,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts';
import { v1 as uuid } from 'uuid';

const useStyles = makeStyles(theme => ({
  root: {
    /*flexGrow: 1,*/
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dashboardDataOverview: {
    /* blue borders */
    '& th,td':  {
      borderColor: '#4F81BD',
      borderWidth: '1px',
      padding: '4px',
    },
    '& tbody:last-of-type tr:last-of-type th, tbody:last-of-type tr:last-of-type td': {
      border: 'none',
    },
    /* headers */
    '& thead tr': {
      backgroundColor: 'white',
    },
    '& thead tr th': {
      color: 'rgba(100, 100, 120, 1) ',
    },
    /* odd even rows */
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#D0D8E8',
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: '#E9EDF4',
    },
  },
  emptyRows: {
    backgroundColor: 'white !important',
    borderBottom: 'none',
  },
  importantRows: {
    '& th, td': {
      color: 'white',
      backgroundColor: '#7030A0',
    },    
  },
  secondaryRowHeader: {
    '& th, td': {
      color: 'white',
      backgroundColor: '#3D85C6',
    },    
  },
  secondaryRows: {
    '& th, td': {
      backgroundColor: '#CFE2F3',
    },    
  },
  tertiaryRowHeader: {
    '& th, td': {
      color: 'white',
      backgroundColor: '#38761D',
    },    
  },
  tertiaryRows: {
    '& th, td': {
      backgroundColor: '#B6D7A8',
    },    
  },
  tertiaryRowsLight: {
    '& th, td': {
      backgroundColor: '#D9EAD3',
    },    
  },
  withSeparation: {
    '& thead tr th':  {
      borderRight: 'none',
    },
    '& th,td':  {
      borderRight: '1px solid #4F81BD',
    },
    '& tbody:last-of-type tr:last-of-type th, tbody:last-of-type tr:last-of-type td': {
      borderRight: '1px solid #4F81BD',
    },
    '& tbody tr th:last-of-type':  {
      borderRight: 'none',
    },
    '& tbody tr td:last-of-type':  {
      borderRight: 'none !important',
    },
  },
  /* odd even rows (alt colors) */
  altStripedRows: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: '#D7E4BD !important',
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: '#ECF1DE !important',
    },
  },
  dashboardInvestOverview: {
    '& th,td':  {
      padding: '28px',
    },
  },
}));

const Assessment = ({ staticContext, ...props }) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();

  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    month: '',
    year: '',
  });
  const [animateCharts, setAnimateCharts] = useState(false);

  /*const mockTableData = {
    2021: [
      { name: 'Januar', 2021: 23 },
      { name: 'Februar', 2021: 42 },
      { name: 'März', 2021: 68 },
      { name: 'April', 2021: 90 },
      { name: 'Mai', 2021: 101 },
      { name: 'Juni', 2021: 120 },
      { name: 'Juli', 2021: 180 },
      { name: 'August', 2021: 501 },
      { name: 'September', 2021: 690 },
      { name: 'Oktober', 2021: 700 },
      { name: 'November', 2021: 720 },
      { name: 'Dezember', 2021: 731 },
    ],
    2022: [
      { name: 'Januar', 2022: 780 },
      { name: 'Februar', 2022: 812 },
      { name: 'März', 2022: 897 },
      { name: 'April', 2022: 911 },
      { name: 'Mai', 2022: 981 },
      { name: 'Juni', 2022: 1051 },
      { name: 'Juli', 2022: 1120 },
      { name: 'August', 2022: 1500 },
      { name: 'September', 2022: 1659 },
      { name: 'Oktober', 2022: 1780 },
      { name: 'November', 2022: 1801 },
    ],
  }*/

  const filterData = {
    months: [
      { name: 'Januar', value: 1 },
      { name: 'Februar', value: 2 },
      { name: 'März', value: 3 },
      { name: 'April', value: 4 },
      { name: 'Mai', value: 5 },
      { name: 'Juni', value: 6 },
      { name: 'Juli', value: 7 },
      { name: 'August', value: 8 },
      { name: 'September', value: 9 },
      { name: 'Oktober', value: 10 },
      { name: 'November', value: 11 },
      { name: 'Dezember', value: 12 },
    ],
    /*years: [
      { name: 2021, value: 2021 },
      { name: 2022, value: 2022 },
    ]*/
  };

  const fetchAssessmentData = useCallback(async (selection) => {
    const data = await dataProvider.getData('statistics', {
      key: 'assessment',
      data: selection,
    });

    return data;
  }, [])

  const currentMonthName = () => {
    const currentMonthIndex = (new Date()).getMonth();

    return filterData.months[currentMonthIndex]?.name;
  }

  const currentYear = () => {
    return (new Date()).getFullYear();
  }

  const yearRangeToCurrent = [...Array(currentYear()+1 - 2021)].map((_,i)=> {
    return { name: i + 2021, value: i + 2021 }
  });

  useEffect(() => {
    setFilter({ months: filterData.months, years: yearRangeToCurrent });

    //setLoading(true);
    fetchAssessmentData().then(response => {
      const currentYearData = {
        community: response.data.community,
        revenue: response.data.revenue,
      };

      setData(currentYearData);
      //setLoading(false);
      setTimeout(() => {
        // chart gets redrawn once after rerender, so let some time pass to prevent jumping/flickering charts
        // TODO: investigate from which component the last redraw comes from
        setAnimateCharts(true);
      }, 500)
    }).catch(console.error);
  }, []);

  const handleFilterChange = (event) => {
    const newSelection = { ...selected, [event.target.name]: event.target.value };

    if(newSelection.year === (new Date()).getFullYear()) {
      const currentMonthIndex = (new Date()).getMonth();
      filterData.months = filterData.months.map((month, index) => {
        if(month.value > currentMonthIndex+1) {
          month.disabled = true;
          // reset month selection if year changed and month would be unselectable:
          if(newSelection.month > currentMonthIndex+1) newSelection.month = '';
        }
        /*if(!isNaN(month.value) && newSelection.month === currentMonthIndex+1) newSelection.month = '';*/

        return month;
      })
    }

    //setFilter(filterData);
    setFilter({ months: filterData.months, years: yearRangeToCurrent });
    setSelected(newSelection)
    if(!newSelection.month || !newSelection.year) return;

    fetchAssessmentData(newSelection).then(response => {
      setAnimateCharts(false);

      const filteredData = {
        community: response.data.community,
        revenue: response.data.revenue,
      };

      setFilteredData(filteredData);
      //setLoading(false);
      setTimeout(() => {
        // chart gets redrawn once after rerender, so let some time pass to prevent jumping/flickering charts
        // TODO: investigate from which component the last redraw comes from
        setAnimateCharts(true);
      }, 500)
    });
  };

  const EmphasizedDot = props => {
    const { cx, cy, stroke, payload, value, fill, r, index, strokeWidth, key, name, emphasize } = props;

    let emphasized;
    if(emphasize === 'current') {
      emphasized = parseInt(props.payload.month) === new Date().getMonth()+1;
    } else {
      emphasized = parseInt(props.payload.month) === selected.month;
    }

    const radius = emphasized ? 7 : r;

    return (
      <Dot key={`${key}-${name.toLowerCase()}`} cx={cx} cy={cy} r={radius} stroke={stroke} fill={fill} strokeWidth={strokeWidth} />
    );
  }

  return (
    <Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="metrics" className={[classes.dashboardDataOverview, classes.withSeparation]}>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>

                  <TableCell colSpan={3} align="center"><b>Aktueller Monat</b></TableCell>
                  <TableCell colSpan={3} align="center"><b>Jahr</b></TableCell>

                  <TableCell>&nbsp;</TableCell>

                  <TableCell colSpan={3} align="center"><b>Monat</b></TableCell>
                  <TableCell colSpan={3} align="center"><b>Jahr</b></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>

                  <TableCell colSpan={3} align="center"><b>{currentMonthName()} {currentYear()}</b></TableCell>
                  <TableCell colSpan={3} align="center"><b>{currentYear()}</b></TableCell>

                  <TableCell>&nbsp;</TableCell>

                  <TableCell colSpan={3}>
                    <FormControl fullWidth size="small" variant="outlined" margin="none">
                      <InputLabel id="filterYear-select-label">Monat auswählen</InputLabel>
                      <Select
                        id="filterMonth"
                        name="month"
                        value={selected.month}
                        label="Monat"
                        disabled={!data}
                        fullWidth
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">
                          <em>Monat auswählen</em>
                        </MenuItem>
                        {filter?.months.map((month) => (
                          <MenuItem value={month.value} key={month.value} disabled={month.disabled}>
                            {month.name}
                          </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <FormControl fullWidth size="small" variant="outlined" margin="none">
                      <InputLabel id="filterYear-select-label">Jahr auswählen</InputLabel>
                      <Select
                        id="filterYear"
                        name="year"
                        value={selected.year}
                        disabled={!data}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value="">
                          <em>Jahr auswählen</em>
                        </MenuItem>
                        {filter?.years.map((year) => (
                          <MenuItem value={year.value} key={year.name}>
                            {year.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell><b>Gesamt Stk.</b></TableCell>
                  <TableCell>&nbsp;</TableCell>

                  <TableCell><nobr>Anzahl Stk.</nobr></TableCell>
                  <TableCell><nobr>DS-Umsatz</nobr> / Bloomer €</TableCell>
                  <TableCell><nobr>Umsatz €</nobr></TableCell>

                  <TableCell><nobr>Anzahl Stk.</nobr></TableCell>
                  <TableCell><nobr>DS-Umsatz</nobr> / Bloomer €</TableCell>
                  <TableCell><nobr>Umsatz €</nobr></TableCell>

                  <TableCell>&nbsp;</TableCell>

                  <TableCell><nobr>Anzahl Stk.</nobr></TableCell>
                  <TableCell><nobr>DS-Umsatz</nobr> / Bloomer €</TableCell>
                  <TableCell><nobr>Umsatz €</nobr></TableCell>

                  <TableCell><nobr>Anzahl Stk.</nobr></TableCell>
                  <TableCell><nobr>DS-Umsatz</nobr> / Bloomer €</TableCell>
                  <TableCell><nobr>Umsatz €</nobr></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow className={classes.importantRows}>
                  <TableCell scope="row" colSpan={17}>bloomUp Kosmos</TableCell>
                </TableRow>

                <TableRow className={classes.importantRows}>
                  <TableCell scope="row">Gesamt Bloomer</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell><b>{data?.community.total.users}</b></TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  
                  <TableCell>{data?.community.month.users}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.month.totalRevenue/data?.community.total.users || 0)}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.month.totalRevenue || 0)}
                  </TableCell>

                  <TableCell>{data?.currentYearTotal}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.year.totalRevenue/data?.community.total.users || 0)}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.year.totalRevenue || 0)}
                  </TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>

                  <TableCell>{filteredData?.community.month.users}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.month.totalRevenue/filteredData?.community.total.users || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.month.totalRevenue || 0) : null}
                  </TableCell>

                  <TableCell>{filteredData?.community.year.users}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.year.totalRevenue/filteredData?.community.total.users || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.year.totalRevenue || 0) : null}
                  </TableCell>
                </TableRow>

                <TableRow style={{ backgroundColor: '#F5F5F5', borderLeft: 'none' }}>
                  <TableCell colSpan={17} >
                    <div style={{ backgroundColor: '#F5F5F5', borderRadius: '7px' }}>
                      <div style={{ width: '100%', height: '380px', visibility: true ? 'visible' : 'hidden' }}>
                        {data &&
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                              width={500}
                              height={300}
                              margin={{
                                top: 30,
                                right: 50,
                                left: 10,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" allowDuplicatedCategory={false} />
                              <YAxis domain={[0, 2500]} />
                              <Tooltip />
                              <Legend />
                              {filteredData && <ReferenceLine x={filterData.months[selected.month - 1]?.name} stroke="green" />}
                              <Line isAnimationActive={animateCharts} animationBegin={400} data={data.community.interval} type="linear" dataKey={'users'} name={'Aktuell'} stroke="#EE5555"  dot={<EmphasizedDot emphasize="current" />} />
                              {filteredData && (new Date()).getFullYear() !== selected.year &&
                                <Line isAnimationActive={animateCharts} animationBegin={400} data={filteredData.community.interval} type="linear" dataKey={'users'} name={'Auswahl'} stroke="#8884d8" dot={<EmphasizedDot emphasize="selected" />} />
                              }
                            </LineChart>
                          </ResponsiveContainer>
                        }
                      </div>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRowHeader}>
                  <TableCell component="th" scope="row" colSpan={17}>User Community</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>zahlende Bloomer</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.subscribed}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.subscribed}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.month.subscriptionRevenue/data?.revenue.month.subscriptions || 0) : null}
                  </TableCell>
                  <TableCell>{data?.revenue.month.subscriptionRevenue}</TableCell>
                  <TableCell>{data?.revenue.year.subscriptions}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.year.subscriptionRevenue/data?.revenue.year.subscriptions || 0) : null}
                  </TableCell>
                  <TableCell>{data?.revenue.year.subscriptionRevenue}</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.subscribed}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.month.subscriptionRevenue/filteredData?.revenue.month.subscriptions || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.month.subscriptionRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{filteredData?.community.year.subscribed}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.year.subscriptionRevenue/filteredData?.revenue.year.subscriptions || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData?.revenue.year.subscriptionRevenue || 0) : null}
                  </TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Family</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.family}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.family}</TableCell>

                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.family}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.family}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.year.family}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Charity</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.charity}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.charity}</TableCell>
                  
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.charity}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.charity}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.year.charity}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Kennenlern-Bloomer</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.evaluation}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.evaluation}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.evaluation}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.evaluation}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.year.evaluation}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Anzahl Bloomer aus Empfehlungen</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.referred}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.referred}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.referred}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.referred}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.month.referred}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Anzahl aus App-Code</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.appcode}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.appcode}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.appcode}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.appcode}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.year.appcode}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.secondaryRows}>
                  <TableCell>Anzahl Bloomer direkt Appstore / HP</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.total.direct}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.community.month.direct}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>{data?.community.year.direct}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.community.month.direct}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData?.community.year.direct}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                  <TableCell>{filteredData && '-'}</TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRowHeader}>
                  <TableCell component="th" scope="row" colSpan={17}>Zusatzverkäufe</TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRows}>
                  <TableCell>Fotobücher Prints</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.total.prints}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.month.prints}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.printRevenue/data.revenue.month.prints || 0) : null}
                  </TableCell>
                  <TableCell>{data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.printRevenue || 0) : null}</TableCell>
                  <TableCell>{data?.revenue.year.prints}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.year.printRevenue/data.revenue.year.prints || 0) : null}
                  </TableCell>
                  <TableCell>
                    <nobr>
                      {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.year.printRevenue || 0) : null}
                    </nobr>
                  </TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.revenue.month.prints}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.printRevenue / filteredData.revenue.month.prints || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.printRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{filteredData?.revenue.year.prints}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.printRevenue / filteredData.revenue.year.prints || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.printRevenue || 0) : null}
                  </TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRows}>
                  <TableCell>Fotobücher PDF-Downloads</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.total.pdfs}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.month.pdfs}</TableCell>
                  
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.pdfRevenue/data?.revenue.month.pdfs || 0) : null}
                  </TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.month.pdfRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{data?.revenue.year.pdfs}</TableCell>
                  <TableCell>{data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.year.pdfRevenue/data?.revenue.year.pdfs || 0) : null}</TableCell>
                  <TableCell>
                    <nobr>
                      {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.year.pdfRevenue || 0) : null}
                    </nobr>
                  </TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.revenue.month.pdfs}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.pdfRevenue / filteredData.revenue.month.pdfs || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.pdfRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{filteredData?.revenue.year.pdfs}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.pdfRevenue/filteredData.revenue.year.pdfs || 0) : null}
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.pdfRevenue || 0) : null}
                  </TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRows}>
                  <TableCell>Fotobücher CSV-Downloads</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.total.csvs}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.month.csvs}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.csvRevenue/data.revenue.month.csvs || 0) : null}
                  </TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data?.revenue.month.csvRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{data?.revenue.month.csvs}</TableCell>
                  <TableCell>
                    {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.csvRevenue/data?.revenue.month.csvs || 0) : null}
                  </TableCell>
                  <TableCell>
                    <nobr>
                      {data ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.csvRevenue || 0) : null}
                    </nobr>
                  </TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{filteredData?.revenue.month.csvs}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.csvRevenue / filteredData.revenue.month.csvs || 0) : null}&nbsp;
                  </TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.month.csvRevenue || 0) : null}
                  </TableCell>
                  <TableCell>{filteredData?.revenue.year.csvs}</TableCell>
                  <TableCell>
                    {filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.csvRevenue/filteredData.revenue.year.csv || 0) : null}
                  </TableCell>
                  <TableCell>{filteredData ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(filteredData.revenue.year.csvRevenue || 0) : null}</TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRowsLight}>
                  <TableCell>Merchandise (Stk.)</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.total.merch}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.month.merch}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>

                <TableRow className={classes.tertiaryRowsLight}>
                  <TableCell>Seminare</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.total.workshops}</TableCell>
                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>{data?.revenue.month.workshops}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>
                  <TableCell>{``}</TableCell>

                  <TableCell className={classes.emptyRows}>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Assessment;