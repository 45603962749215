import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useDataProvider, useGetIdentity, Loading, Error } from 'react-admin';
import {
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import { v1 as uuid } from 'uuid';

const useStyles = makeStyles(theme => ({
  root: {
    /*flexGrow: 1,*/
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dashboardDataOverview: {
    /* blue borders */
    '& th,td':  {
      borderColor: '#4F81BD',
      borderWidth: '1px',
      padding: '4px',
    },
    '& tbody:last-of-type tr:last-of-type th, tbody:last-of-type tr:last-of-type td': {
      border: 'none',
    },
    /* headers */
    '& thead tr': {
      backgroundColor: 'white',
    },
    '& thead tr th': {
      color: 'rgba(100, 100, 120, 1) ',
    },
    /* odd even rows */
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: '#D0D8E8',
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: '#E9EDF4',
    },
  },
  importantRows: {
    '& th, td': {
      color: 'white',
      backgroundColor: '#7030A0',
    },    
  },
  withSeparation: {
    '& thead tr th':  {
      borderRight: 'none',
    },
    '& th,td':  {
      borderRight: '1px solid #4F81BD',
    },
    '& tbody:last-of-type tr:last-of-type th, tbody:last-of-type tr:last-of-type td': {
      borderRight: '1px solid #4F81BD',
    },
    
    '& tbody tr td:last-of-type':  {
      borderRight: 'none !important',
    },
  },
  /* odd even rows (alt colors) */
  altStripedRows: {
    '& tr:nth-of-type(odd)': {
      backgroundColor: '#D7E4BD !important',
    },
    '& tr:nth-of-type(even)': {
      backgroundColor: '#ECF1DE !important',
    },
  },
  dashboardInvestOverview: {
    '& th,td':  {
      padding: '28px',
    },
  },
}));

const Dashboard = ({ staticContext, ...props }) => {
  const { identity, isLoading: identityLoading } = useGetIdentity();

  const classes = useStyles();

  const dataProvider = useDataProvider();

  const userMockData = [
    { name: 'Bloomer', value: 900, color: '#BB8FCE' },
    { name: 'Abos', value: 100, color: '#FF8042' },
  ];

  const regionMockData = [
    { name: 'Deutschland', value: 550, color: '#58D68D' },
    { name: 'Österreich', value: 100, color: '#F7DC6F' },    
    { name: 'Schweiz', value: 50, color: '#EB984E' },
  ];

  const usageMockData = [
    { name: 'Erlebnisse teilen', longName: 'Gemeinsame Erlebnisse teilen', key: 'sharing', value: 551, color: '#0088FE' },
    { name: 'Selbstreflexion', longName: 'Selbstreflexion', key: 'introspection', value: 319, color: '#0088FE' },
    { name: 'berufliche Projekte', longName: 'berufliche Projekte umsetzen', key: 'projects', value: 120, color: '#0088FE' },
    { name: 'private Ziele', longName: 'private Ziele erreichen', key: 'private', value: 401, color: '#0088FE' },
    { name: 'berufliche Ziele', longName: 'berufliche Ziele erreichen', key: 'work', value: 63, color: '#0088FE' },
    { name: 'Weiß noch nicht', longName: 'Weiß noch nicht', key: 'unknown', value: 202, color: '#0088FE' },
  ];

  const investMockData = {
    investment: 5000,
    lots: 1,
    share: 0.20,
    revenue: 1460.40,
    currentEarnings: 2.92,
    totalEarnings: 17.92,
  };

  const renderCustomizedBarLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    const labelWidth = value?.toString().length * 12;
    return (
      <g>
        <rect x={x + (width-labelWidth) / 2} y={y-23-5} rx="7" ry="7" width={labelWidth} height="23" fill="#555555" />
        <text x={x + width / 2} y={y - radius - 5} fill="#fff" textAnchor="middle" dominantBaseline="middle">{value}</text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div tabIndex="-1" role="dialog" className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top">
          <div className="recharts-default-tooltip" style={{ margin: '0px', padding: '10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
            <p className="recharts-tooltip-label" style={{ margin: '0px' }}>{payload[0].payload.longName}</p>
            <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
              <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: 'rgb(136, 132, 216)', }}>
                <span className="recharts-tooltip-item-name">&nbsp;</span>
                <span className="recharts-tooltip-item-separator">&nbsp;</span>
                <span className="recharts-tooltip-item-value">{payload[0].payload.value} </span>
                <span className="recharts-tooltip-item-unit">Bloomer</span>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return null;
  };

  const [data, setData] = useState(null); // { pie: userMockData, table: { total: 0, subscribed: 0 } }
  const [investment, setInvestment] = useState(null);
  const [animateCharts, setAnimateCharts] = useState(false);

  const fetchStatisticsData = useCallback(async () => {
    const data = await dataProvider.getData('statistics', { key: 'general' });

    return data;
  }, [])

  useEffect(() => {
    fetchStatisticsData().then(response => {
      const communityTotal = response.data.community.total;

      const userPieData = [
        { name: `Bloomer`, value: communityTotal.users, color: '#BB8FCE' },
        { name: `Charity`, value: communityTotal.charity, color: '#8FCEBB' },
        { name: `Abos`, value: communityTotal.subscribed, color: '#C0504D' },    
      ];

      const countryPieData = [
        { name: 'Deutschland', key: 'de', value: communityTotal.countries.de, color: '#58D68D' },
        { name: 'Österreich', key: 'at', value: communityTotal.countries.at, color: '#4F81BD' },
        { name: 'Schweiz', key: 'ch', value: communityTotal.countries.ch, color: '#EB984E' },
      ]
      const countryPieDataFiltered = countryPieData.filter((country, sum) => {
        return !!communityTotal.countries[country.key];
      });

      const community = response.data.community;
      const revenue = response.data.revenue;
      const usage = response.data.usage;

      setData({
        community: community,
        revenue: revenue,

        userPie: userPieData,
        countryPie: countryPieDataFiltered,
        usageBar: usage,
      });
      setTimeout(() => {
        // chart gets redrawn once after rerender, so let some time pass to prevent jumping/flickering charts
        // TODO: investigate from which component the last redraw comes from
        setAnimateCharts(true);
    }, 500)
    }).catch(console.error);
  }, []);

  const fetchInvestmentData = useCallback(async () => {
    const data = await dataProvider.getData('statistics', { key: 'investment' });

    return data;
  }, [])

  useEffect(() => {
    if(identity?.role !== 'watcher') return;
    if(!data) return;

    fetchInvestmentData().then(response => {
      const investData = response.data;
      // calculate invest revenues from statistics
      const currentRevenue = ((data.revenue.month.subscriptionRevenue)/100)*81;
      const totalRevenue = ((data.revenue.total.subscriptionRevenue)/100)*81;

      const currentRevenueSharePerInvestor = (currentRevenue * 0.04) / 20; //  * (investData.share/100)
      const totalRevenueSharePerInvestor = (totalRevenue * 0.04) / 20;

      const currentEarnings = investData.share ? currentRevenueSharePerInvestor * investData.lots : 0;
      const totalEarnings = investData.share ? totalRevenueSharePerInvestor * investData.lots : 0;

      const revenueData = {
        revenue: currentRevenue,
        currentEarnings: currentEarnings,
        totalEarnings: totalEarnings,
      };

      setInvestment({ ...investData, ...revenueData });
    }).catch(console.error);
  }, [identity, data]);

  return (
    <Fragment>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Dashboard</Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{ backgroundColor: '#F5F5F5', borderRadius: '7px' }}>
              <div style={{ width: '100%', height: '380px', visibility: 'visible' }}>
                {data &&
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      width={500}
                      height={300}
                      margin={{
                        top: 30,
                        right: 50,
                        left: 10,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" allowDuplicatedCategory={false} />
                      <YAxis domain={[0, 2500]} />
                      <Tooltip />
                      <Legend />
                      <Line isAnimationActive={animateCharts} animationBegin={400} data={data.community.interval} type="monotone" dataKey={'users'} name="Gesamt" stroke="#BB8FCE" />
                      <Line isAnimationActive={animateCharts} animationBegin={400} data={data.community.interval} type="monotone" dataKey={'subscribed'} name="Abos" stroke="#C0504D" />
                      <Line isAnimationActive={animateCharts} animationBegin={400} data={data.community.interval} type="monotone" dataKey={'charity'} name="Charity" stroke="#8FCEBB" />
                    </LineChart>
                  </ResponsiveContainer>
                }
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            {data &&<Typography gutterBottom><b>{data.community.total.users} Bloomer</b></Typography>}
            <div style={{ backgroundColor: '#F5F5F5', marginBottom: '16px', borderRadius: '7px' }}>
              <div style={{ width: '100%', height: '380px', visibility: animateCharts ? 'visible' : 'hidden' }}>
                {data &&
                <ResponsiveContainer width="100%" key={uuid()}>
                  <PieChart>
                    {/*<Pie data={[{ background: 100 }]} dataKey="background" outerRadius={150} innerRadius={75} fill="lightgray" isAnimationActive={false} />*/}
                    <Pie isAnimationActive={animateCharts} animationBegin={400} dataKey="value" data={data?.userPie} outerRadius={150} innerRadius={75} fill="#8884d8" label={cell => cell.name}>
                      {data?.userPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                }
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={props.permissions?.includes('readInvestData') ? 5 : 12} sm={props.permissions?.includes('readInvestData') ? 5 : 8}>
            {data &&
            <TableContainer component={Paper}>
              <Table size="small" aria-label="metrics" className={[classes.dashboardDataOverview, classes.withSeparation]}>
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell><b><nobr>Ø Umsatz</nobr></b></TableCell>
                    <TableCell><b><nobr>Umsatz € Auswahl:</nobr> aktueller Monat / Jahr</b></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow className={classes.importantRows}>
                    <TableCell component="th" scope="row">
                      bloomUp-Kosmos
                      <br />
                      Gesamt Bloomer & -umsatz
                    </TableCell>
                    <TableCell>{data.community.total.users}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((data.revenue.total.subscriptionRevenue + data.revenue.total.printRevenue + data.revenue.total.pdfRevenue + data.revenue.total.csvRevenue)/data.community.total.users || 0)}/Bloomer</nobr></TableCell>
                    <TableCell>
                      <nobr>
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.subscriptionRevenue + data.revenue.month.printRevenue)}
                        {' '}/ {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.subscriptionRevenue + data.revenue.total.printRevenue)} p.A.
                      </nobr>
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableHead>
                  <TableRow>
                    <TableCell><b>User-Community</b></TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Zahlende Bloomer
                    </TableCell>
                    <TableCell>{data.community.total.subscribed}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.subscriptionRevenue/data.community.month.subscribed || 0)}/Bloomer</nobr></TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.subscriptionRevenue)}
                    {' '}/ {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.subscriptionRevenue)} p.A.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Family
                    </TableCell>
                    <TableCell>{data.community.total.family}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Charity
                    </TableCell>
                    <TableCell>{data.community.total.charity}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Kennenlern-Bloomer
                    </TableCell>
                    <TableCell>{data.community.total.evaluation}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <nobr>Anzahl Bloomer nach Empfehlungen</nobr>
                    </TableCell>
                    <TableCell>{data.community.total.referred}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <nobr>Anzahl Bloomer nach Appcode</nobr>
                    </TableCell>
                    <TableCell>{data.community.total.appcode}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <nobr>Anzahl Bloomer direkt Appstore / Homepage</nobr>
                    </TableCell>
                    <TableCell>{data.community.total.direct}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableBody>

                <TableHead>
                  <TableRow>
                    <TableCell><b>Zusatzverkäufe</b></TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.altStripedRows}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Gesamtanzahl erstellter Fotobücher
                    </TableCell>
                    <TableCell>{data.revenue.total.prints}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.printRevenue/data.revenue.total.prints || 0)}/Buch</nobr></TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.printRevenue)} p.A.</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Erstellte Fotobücher aktueller Monat
                    </TableCell>
                    <TableCell>{data.revenue.month.prints}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.printRevenue/data.revenue.month.prints || 0)}/Buch</nobr></TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.printRevenue)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Gesamtanzahl erstellte PDF-Prints
                    </TableCell>
                    <TableCell>{data.revenue.total.pdfs}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.pdfRevenue/data.revenue.total.pdfs || 0)}/Buch</nobr></TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.total.pdfs * 4.95)} p.A.</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <nobr>Gesamtanzahl erstellte PDF-Prints aktueller Monat</nobr>
                    </TableCell>
                    <TableCell>{data.revenue.month.pdfs}</TableCell>
                    <TableCell><nobr>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.pdfRevenue/data.revenue.month.pdfs || 0)}/Buch</nobr></TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.pdfRevenue)}</TableCell>                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>

        <Grid item xs={6} sm={3} hidden={!props.permissions?.includes('readInvestData')}>
          {investment &&
            <TableContainer component={Paper}>
              <Table size="small" aria-label="metrics" className={[classes.dashboardDataOverview, classes.dashboardInvestOverview]}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <b>Invest</b>
                    </TableCell>
                    <TableCell>
                      <b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(investment.investment)}</b>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Lose
                    </TableCell>
                    <TableCell>{investment.lots} Stk.</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      UB
                    </TableCell>
                    <TableCell>{investment.share} %</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Abo Monatsumsatz
                    </TableCell>
                    <TableCell>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(investment.revenue)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Abo UB Monat
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#FCD5B5' }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(investment.currentEarnings)}
                    {' '}<span style={{ color: '#FCD5B5' }}>(von {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.revenue.month.sbscriptionRevenue)})</span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      Abo UB Gesamt
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#FCD5B5' }}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(investment.totalEarnings)}
                    {' '}<span style={{ color: '#FCD5B5' }}>(von {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format( ((data.revenue.total.subscriptionRevenue)/100)*81)})</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography gutterBottom><b>Bloomer Regionen</b></Typography>
            <div style={{ backgroundColor: '#F5F5F5', borderRadius: '7px' }}>
            <div style={{ width: '100%', height: '360px', visibility: animateCharts ? 'visible' : 'hidden' }}>
              {data &&
              <ResponsiveContainer width="100%" key={uuid()}>
                <PieChart>
                  <Pie isAnimationActive={animateCharts} animationBegin={400} dataKey="value" data={data?.countryPie} outerRadius={75} innerRadius={25} fill="#8884d8" label={cell => cell.name}>
                    {data?.countryPie.map((entry, index) => {
                      return !entry.hidden && <Cell key={`cell-${index}`} fill={entry.color} />
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              }
            </div>
            </div>
          </Paper>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Paper className={classes.paper}>
            <Typography gutterBottom><b>App Verwendungszweck</b></Typography>
            <div style={{ backgroundColor: '#F5F5F5', borderRadius: '7px' }}>
              <div style={{ width: '100%', height: '360px', visibility: animateCharts ? 'visible' : 'hidden' }}>
                {data &&
                <ResponsiveContainer width="100%" height="100%" key={uuid()}>
                <BarChart width={500} height={300} data={data.usageBar} margin={{ top: 40, right: 40, left: 10, bottom: 10 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip dataKey="longName" data={data.usageBar} content={<CustomTooltip />} />
                  <Bar isAnimationActive={animateCharts} animationBegin={400} dataKey="value" fill="#8884d8" minPointSize={5}>
                    <LabelList dataKey="value" content={renderCustomizedBarLabel} />
                  </Bar>
                </BarChart>
                </ResponsiveContainer>
                }
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Dashboard;