import React from 'react';
import {
  useLocale,
  Create,
  TextInput,
  DateInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const AppcodeCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const AppcodeCreate = ({ onCancel, ...props }) => {
  const locale = useLocale();

  return (
    <Create title=" " {...props}>
      <SimpleForm redirect="list" toolbar={<AppcodeCreateToolbar onCancel={onCancel} />}>
        <TextInput source="name" validate={required()} />
        <TextInput source="code" validate={required()} />
        <DateInput source="validFrom" locales={locale} />
        <DateInput source="validTo" locales={locale} />
      </SimpleForm>
    </Create>
  );
};

export default AppcodeCreate;