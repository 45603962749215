import React from 'react';
import { List, Datagrid, TextField, EditButton, FunctionField } from 'react-admin';

const UserList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField source="title" render={record => record.title.rendered} />
        <TextField source="date" />
        <TextField source="status" />
        <EditButton basePath="/pages" />
      </Datagrid>
    </List>
  );
};

export default UserList;
