import React from 'react';
import {
  Create,
  TextInput,
  DateInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const GamificationCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const GamificationCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props}>
    <SimpleForm redirect="list" toolbar={<GamificationCreateToolbar onCancel={onCancel} />}>
      <TextInput source="name" validate={required()} />
      <DateInput source="validFrom" />
      <DateInput source="validTo" />
    </SimpleForm>
  </Create>
);

export default GamificationCreate;