import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, makeStyles } from '@material-ui/core';
import {
  List,
  EditButton,
  DeleteButton,
  Datagrid,
  CardActions,
  CreateButton,
  FunctionField,
  TextField,
} from 'react-admin';
import { Route } from 'react-router';
import FitCreate from './FitCreate';
import FitEdit from './FitEdit';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const FitListActions = ({ basePath }) => (
   <CardActions>
       <CreateButton basePath={basePath} />
   </CardActions>
);

const FitList = props => {
  const classes = useStyles();

  const handleClose = () => {
    props.push("/fits");
  };

  return (
    <React.Fragment>
      <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} actions={<FitListActions />}>
        <Datagrid>
          <TextField source="category" />
          <TextField source="title" />
          <TextField source="url" />
          <EditButton basePath="/fit" />
          <DeleteButton basePath="/fit" />
        </Datagrid>
      </List>

      <Route path="/fit/create">
        {({ match }) => {
          //console.log('CREATE?')
          return;
          return (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <FitCreate onCancel={handleClose} className={classes.drawerContent} {...props} />
            </Drawer>
          )
        }}
      </Route>
      <Route path="/fit/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <FitEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { push }
)(FitList);


