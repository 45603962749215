import React from 'react';
import {
  Create,
  TextInput,
  AutocompleteInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const QuoteCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const QuoteCreate = ({ onCancel, ...props }) => {
  return (
    <Create title=" " {...props}>
      <SimpleForm redirect="list" toolbar={<QuoteCreateToolbar onCancel={onCancel} />}>
        <TextInput source="text" validate={required()} />
        <TextInput source="author" />
      </SimpleForm>
    </Create>
  );
};

export default QuoteCreate;