import React from 'react';
import {
  Create,
  TextInput,
  AutocompleteInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const FitCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const FitCreate = ({ onCancel, ...props }) => {
  return (
  <Create title=" " {...props}>
    <SimpleForm redirect="list" toolbar={<FitCreateToolbar onCancel={onCancel} />}>
      <TextInput source="category" validate={required()} />
      <TextInput source="title" validate={required()} />
      <TextInput source="url" validate={required()} />
    </SimpleForm>
  </Create>
)};

export default FitCreate;