import React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    required,
    SaveButton,
    Toolbar,
    translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const SettingEditToolbar = translate(({ onCancel, translate, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        <Button m={5} onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
));

const SettingEdit = ({ onCancel, ...props }) => (
    <Edit title=" " {...props}>
        <SimpleForm toolbar={<SettingEditToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="value" validate={required()} />
        </SimpleForm>
    </Edit>
);

export default SettingEdit;
