import React from 'react';
import { Admin, Resource, usePermissions } from 'react-admin';
import { Route } from "react-router-dom";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';
import merge from 'lodash/merge';
import { VariantContext } from './hooks/VariantContext';
import authProvider from './services/authProvider';
import LoginPage from './components/LoginPage';
//import CustomAppBar from './components/AppBar';
import AppMenu from './components/AppMenu';
//import CustomLayout from './components/Layout';
import SettingList from './components/SettingList';
import SettingCreate from './components/SettingCreate';
import AppcodeList from './components/AppcodeList';
import AppcodeCreate from './components/AppcodeCreate';
import AppcodeEdit from './components/AppcodeEdit';
import GamificationList from './components/GamificationList';
import GamificationCreate from './components/GamificationCreate';
import GamificationEdit from './components/GamificationEdit';
import UserList from './components/UserList';
import UserCreate from './components/UserCreate';
import UserEdit from './components/UserEdit';
import PageList from './components/PageList';
import PageEdit from './components/PageEdit';
import NotificationList from './components/NotificationList';
import NotificationCreate from './components/NotificationCreate';
import NotificationEdit from './components/NotificationEdit';
import FitList from './components/FitList';
import FitCreate from './components/FitCreate';
import FitEdit from './components/FitEdit';
import QuoteList from './components/QuoteList';
import QuoteCreate from './components/QuoteCreate';
import QuoteEdit from './components/QuoteEdit';
//import PaymentList from './components/PaymentList';
import Dashboard from './components/Dashboard';
import Assessment from './components/Assessment';
import InvoiceList from './components/InvoiceList';
import { defaultTheme } from 'react-admin';
import bloomupTheme from './themes/bloomup';
import ljsTheme from './themes/ljs';
import { fetchUtils } from 'react-admin';
import dataProvider from './services/dataProvider';

import UserIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PagesIcon from '@material-ui/icons/MenuBook';
import NotificationIcon from '@material-ui/icons/DynamicFeed';
import CodesIcon from '@material-ui/icons/BorderOuter';
import GamesIcon from '@material-ui/icons/SportsEsports';
import PaymentIcon from '@material-ui/icons/Payment';
import FitIcon from '@material-ui/icons/FitnessCenter';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import InvoiceIcon from '@material-ui/icons/Receipt';

const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de');

function App() {
  const restUrl = process.env.REACT_APP_API_URL;


  const { host } = window.location;
  const appVariant = host.includes('ljs') ? 'ljs' : 'bloomup';

  const theme = merge({}, defaultTheme, appVariant === 'bloomup' ? bloomupTheme : ljsTheme);

  if(appVariant === 'ljs') {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', '/favicon-ljs.ico');
  }

  const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const authJson = localStorage.getItem('auth');
    if(authJson) {
      const { tokens } = JSON.parse(authJson);
      const jwtToken = tokens.access.token;
      options.headers.set('Authorization', `Bearer ${jwtToken}`);
    }

    return fetchUtils.fetchJson(url, options);
  };

  const { permissions } = usePermissions();

  return (
    <VariantContext.Provider value={{ appVariant: appVariant }}>
      <Admin
        loginPage={LoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider(restUrl, httpClient)}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        menu={AppMenu}
        //layout={CustomLayout}
        theme={theme}
        customRoutes={[
          <Route path="/assessment" component={Assessment} />
        ]}
      >
        {permissions => [
          <></>, // <- Admin component with no children will throw an error
          permissions.includes('manageSettings') ? <Resource name="settings" icon={SettingsIcon} list={SettingList}></Resource> : null,
          /*<Resource name="pages" icon={PagesIcon} list={PageList} edit={PageEdit}></Resource>*/,
          permissions.includes('manageNotifications') ? <Resource name="notifications" icon={NotificationIcon} list={NotificationList} create={NotificationCreate} edit={NotificationEdit}></Resource> : null,
          permissions.includes('manageUsers') ? <Resource name="users" icon={UserIcon} list={UserList} edit={UserEdit}></Resource> : null,
          permissions.includes('manageAppcodes') ? <Resource name="appcodes" icon={CodesIcon} list={AppcodeList} create={AppcodeCreate} edit={AppcodeEdit}></Resource> : null,
          permissions.includes('manageGamification') ? <Resource name="rewards/settings" options={{ label: 'Gamification' }} icon={GamesIcon} list={GamificationList} create={GamificationCreate} edit={GamificationEdit}></Resource> : null,
          //permissions.includes('managePayments') ? <Resource name="payments" icon={PaymentIcon} list={PaymentList}></Resource> : null,
          permissions.includes('manageFitContent') ? <Resource name="fit" icon={FitIcon} list={FitList} create={FitCreate} edit={FitEdit}></Resource> : null,
          permissions.includes('manageQuotes') ? <Resource name="quotes/admin" icon={QuoteIcon} list={QuoteList} create={QuoteCreate} edit={QuoteEdit}></Resource> : null,
          permissions.includes('managePayments') ? <Resource name="payments" icon={InvoiceIcon} list={InvoiceList}></Resource> : null,
        ]}
      </Admin>
    </VariantContext.Provider>
  );
}

export default App;
