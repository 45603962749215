import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  NumberInput,
  TextField,
  ReferenceField,
  SelectInput,
  BooleanInput,
  TopToolbar,
  ShowButton,
  useGetIdentity,
  usePermissions,
  useShowContext,
  useDataProvider,
  Loading,
  Error,
} from 'react-admin';
import { QRCode } from 'react-qrcode-logo';
import {
  Box,
  Grid,
  Typography,
  Button,
  Link,
  Card,
  CardContent,
  Badge,
  makeStyles,
} from '@material-ui/core';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import InvoiceIcon from '@material-ui/icons/Receipt';
import UserIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles({
  linkbox: {
    padding: '6px 13px',
    width: '230px',
    margin: '6px 0 13px 0',
    textAlign: 'center',
    '& .MuiTypography-root': {
      textAlign: 'left',
    }
  },
  radiobox: {
    marginBottom: '6px',
    '& label': {
      marginLeft: 0,
      padding: '4px',
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    }
  },
  tintCyan: {
    backgroundColor: '#93CDDD',
  },
  tintBlue: {
    backgroundColor: '#C6D9F1',
  },
  tintViolet: {
    backgroundColor: '#9999FF',
  },
  tintLightViolet: {
    backgroundColor: '#CCCCFF',
  },
  tintGreen: {
    backgroundColor: '#C3D69B',
  },
  tintLightGreen: {
    backgroundColor: '#ECF1DE',
  },
  tintOrange: {
    backgroundColor: '#FCD5B5',
  },
  untint: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F5F5F5',
    }
  },
  listLinkIcon: {
    width: '0.75em',
    height: '0.75em',
    marginTop: '5px',
  },
  noBorder: {
    border: 'none !important',
  },
  // Add currency to NumberInput via CSS, so no additional input mask/custom input library is needed
  inputWithCurrencySymbol: {
    '&:after': {
      content: '"€"',
      position: 'absolute',
      top: '23px',
      left: '48px',
    }
  },
  root: {
    // overrides blue selection border (https://github.com/mui/mui-x/issues/2754)
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    // position action bar a bit further at bottom to prevent cutting off the relative positioned list of referred users
    '& .RaToolbar-desktopToolbar': {
      marginTop: '55px',
    },
  },
});

const InvoiceList = (props) => {
  const classes = useStyles();

  const headers = [
    {
      field: 'date',
      headerAlign: 'center',
      headerName: 'Date',
      //width: 80,
      valueGetter: (row) => {
        return new Intl.DateTimeFormat('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(row.value));
      },
    },
    {
      field: 'type',
      headerAlign: 'center',
      headerName: 'Typ',
      //width: 87,
    },
    {
      field: 'actions',
      type: 'actions',
      headerAlign: 'center',
      headerName: 'Link',
      //width: 10,
      getActions: (params) => {
        return [
          <GridActionsCellItem icon={<InvoiceIcon />} component={() => {
            return <a href={params.row.invoiceUrl} target="_blank"><InvoiceIcon  className={['MuiSvgIcon', classes.listLinkIcon]} /></a>;
          }} />
        ]
      },
    },
  ];

  //const parentCell = props.popupAtPosition.current;
  //const parentCellPosition = parentCell.getBoundingClientRect();

  const xPosition = 325;
  const yPosition = -30;
  const backgroundColor = '#9999FF';

  const positioningStyles = props.popupAtPosition ? {
    width: '650px',
    position: 'relative',
    top: `${yPosition}px`,
    left: `${xPosition}px`,
    backgroundColor: backgroundColor,
  } : {};

  if(!props.invoices || !props.invoices.length) return <div style={{ padding: '22px 0', ...positioningStyles }}>- keine -</div>;

  const visibleInvoices = Math.min(10, props.invoices.length);
  const listHeight = 94 + visibleInvoices * 36;

  return (
    <div style={{ height: listHeight, ...positioningStyles }}>
      <DataGrid
        density="compact"
        rows={props.invoices}
        columns={headers}
        pageSize={10}
        isRowSelectable={false}
        disableColumnFilter={true}
        rowsPerPageOptions={[10]}
        className={classes.noBorder}
      />
    </div>
  );
};

const UserList = (props) => {
  const classes = useStyles();

  const headers = [
    {
      field: 'createdAt',
      editable: false,
      isCellEditable: () => { console.log('MEH') },
      isCellSelectable: false,
      headerAlign: 'center',
      headerName: 'Anmeldung',
      //width: 80,
      valueGetter: (row) => {
        return new Intl.DateTimeFormat('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(row.value));
      },
    },
    {
      field: 'name',
      headerAlign: 'center',
      headerName: 'User',
      //width: 87,
    },
    {
      field: 'actions',
      type: 'actions',
      headerAlign: 'center',
      headerName: 'Link',
      //width: 10,
      getActions: (params) => {
        const userEditUrl = generatePath("/#/users/:id", { id: params.row.id });
        return [
          <GridActionsCellItem icon={<InvoiceIcon />} component={() => {
            return <a href={userEditUrl} target="_blank"><UserIcon  className={['MuiSvgIcon', classes.listLinkIcon]} /></a>;
          }} />
        ]
      },
    },
  ];

  //const parentCell = props.popupAtPosition.current;
  //const parentCellPosition = parentCell.getBoundingClientRect();
  const parentCellClasses = props.popupAtPosition.current.className;

  const xPosition = 325;
  const yPosition = -55;
  const backgroundColor = parentCellClasses.includes('tintLightViolet') ? '#CCCCFF' : '#ECF1DE';

  console.log(props.popupAtPosition.current, parentCellClasses, parentCellClasses.includes('tintLightViolet'));

  const positioningStyles = props.popupAtPosition ? {
    width: '650px',
    position: 'relative',
    top: `${yPosition}px`,
    left: `${xPosition}px`,
    backgroundColor: backgroundColor,
    overflow: 'hidden',
  } : {};

  if(!props.users || !props.users.length) return <div style={{ padding: '22px 0', ...positioningStyles }}>- keine -</div>;

  const visibleUsers = Math.min(10, props.users.length);
  const listHeight = 94 + visibleUsers * 36;

  return (
    <div style={{ height: listHeight, ...positioningStyles }}>
      <DataGrid
        density="compact"
        rows={props.users}
        columns={headers}
        pageSize={10}
        //isRowSelectable={false}
        disableSelectionOnClick={true}
        disableColumnFilter={true}
        rowsPerPageOptions={[10]}
        className={classes.noBorder}
      />
    </div>
  );
};

const UserEdit = (props) => {
  const classes = useStyles();

  const { identity, isLoading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();

  const [userdataRows, setUserdataRows] = useState([]);
  const [userMetrics, setUserMetrics] = useState({});

  const [invoices, setInvoices] = useState([]);
  const [showInvoiceList, setShowInvoiceList] = useState(false);

  const [familyNetwork, setFamilyNetwork] = useState([]);
  const [showFamilyNetworkList, setShowFamilyNetworkList] = useState(false);

  const [bloomerNetwork, setBloomerNetwork] = useState([]);
  const [showBloomerNetworkList, setShowBloomerNetworkList] = useState(false);

  const dataProvider = useDataProvider();

  const sendTestmailToUser = (data) => {
    const authJson = localStorage.getItem('auth');
    const { tokens } = JSON.parse(authJson);
    const jwtToken = tokens.access.token;

    const payload = {
      uuid: data.uuid,
      email: data.email,
      password: 'KEEPVALUE#1',
      forceResend: true
    };

    const restUrl = process.env.REACT_APP_API_URL;
    const request = new Request(`${restUrl}/auth/register-complete`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
           //console.log('data', data)
      })
      .catch(() => {
        throw new Error('Network error');
      });
  }

  const transform = (user) => {
    if('evaluation' in user) {
        delete user.evaluation;
    }

    return user;
  };

  const PostEditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <Button color="primary" disabled={!data?.email} onClick={() => sendTestmailToUser(data)}>Verifizierungsmail erneut senden</Button>
    </TopToolbar>
  );

  const fetchUserMetrics = useCallback(async () => {
    const data = await dataProvider.getData(props.resource, { key: 'metrics', id: props.id });

    return data;
  }, [])

  useEffect(() => {
    fetchUserMetrics().then(response => {
      setUserMetrics({
        rewardPoints: response.data.rewardPoints,
        rewardLevel: response.data.rewardLevel,
        referredUsers: response.data.referredUsers,
        referredSubscribers: response.data.referredSubscribers,
        trialPeriodEndDate: new Date(response.data.trialPeriodEnd),
        subscriptionInterval: response.data.subscriptionInterval,
        subscriptionStartDate: new Date(response.data.subscriptionStart),
        subscriptionEndDate: new Date(response.data.subscriptionEnd),
        subscriptionCancelDate: new Date(response.data.subscriptionCancel) || null,
      });
    }).catch(console.error);
  }, []);

  useEffect(() => {
    const authJson = localStorage.getItem('auth');
    const { tokens } = JSON.parse(authJson);
    const jwtToken = tokens.access.token;

    const request = new Request(`${process.env.REACT_APP_API_URL}/users/data`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      }),
    });
    fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
          setUserdataRows(data.results);
      })
      .catch(() => {
        throw new Error('Network error');
      });
  }, []);

  const fetchInvoices = useCallback(async () => {
    const data = await dataProvider.getList(`users/${props.id}/invoices`, { pagination: { page: 1, perPage: 10 }, sort: { field: 'createdAt', order: 'DESC' }});

    return data;
  }, [])

  const loadInvoiceList = () => {
    if(!invoices.length) {
      fetchInvoices().then(response => {
        setInvoices(response.data);
      }).catch(console.error);
    }

    setShowInvoiceList(!showInvoiceList);
  };

  const fetchFamily = useCallback(async () => {
    const data = await dataProvider.getList(`users/${props.id}/family`, { pagination: { page: 1, perPage: 10 }, sort: { field: 'createdAt', order: 'DESC' }});

    return data;
  }, [])

  const loadFamilyNetworkList = () => {
    if(!familyNetwork.length) {
      fetchFamily().then(response => {
        setFamilyNetwork(response.data);
      }).catch(console.error);
    }

    setShowFamilyNetworkList(!showFamilyNetworkList);
  };

  const fetchReferred = useCallback(async () => {
    const data = await dataProvider.getList(`users/${props.id}/referred`, { pagination: { page: 1, perPage: 10 }, sort: { field: 'createdAt', order: 'DESC' }});

    return data;
  }, [])

  const loadBloomerNetworkList = () => {
    if(!bloomerNetwork.length) {
      fetchReferred().then(response => {
        setBloomerNetwork(response.data);
      }).catch(console.error);
    }

    setShowBloomerNetworkList(!showBloomerNetworkList);
  };

  const countryNames = {
    'de': 'Deutschland',
    'at': 'Österreich',
    'ch': 'Schweiz',
  };

  const userRoles = [
    { id: 'user', name: 'User' },
    { id: 'watcher', name: 'Betrachter' },
    { id: 'employee', name: 'Mitarbeiter' },
    { id: 'admin', name: 'Admin' },
    { id: 'superadmin', name: 'Super-Admin' },
  ];

  const taxReducibleOptions = [
    { item: true, label: 'Ja' },
    { item: false, label: 'Nein' },
  ];

  const investmentRoundOptions = [
    { item: 1, label: '1 (ab 5000 Bloomern)' },
    { item: 2, label: '2 (ab 15000 Bloomern)' },
  ];

  const subscriptionIntervalLabel = {
    yearly: 'jährlich',
    monthly: 'monatlich',
  };

  const canChangeRole = () => {
    return permissions?.includes('manageRoles') && identity?.id !== props.id;
  }

  const invoiceCell = useRef(null);
  const familyCell = useRef(null);
  const networkCell = useRef(null);

  return (
    <Edit title="Create user" actions={<PostEditActions />} transform={transform} {...props}>
      <SimpleForm>
        <TextInput source="id" inputProps={{className: classes.tintCyan}} disabled />
        <TextInput source="uuid" inputProps={{className: classes.tintCyan}} />

        {permissions?.includes('seeRoles') ? <SelectInput source="role" label="Rolle" choices={userRoles} disabled={!canChangeRole()} inputProps={{className: classes.tintCyan}} /> : null}

        <BooleanInput source="charity" label="Charity" className={[classes.radiobox, classes.tintBlue]} />
        <TextInput source="appcode" label="LJS-AppCode" inputProps={{className: classes.tintBlue}} />

        <TextInput source="name" label="Username" inputProps={{className: classes.tintViolet}} />
        <TextInput source="email" inputProps={{className: classes.tintViolet}} />
        <BooleanInput source="isEmailVerified" label="Email ist verifiziert" className={[classes.radiobox, classes.tintViolet]} />
        <TextInput
          source="createdAt"
          format={
            date => new Intl.DateTimeFormat('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }).format(userMetrics.trialPeriodEndDate)}
          label="bloomUp-30 Tage"
          helperText={false}
          inputProps={{className: classes.tintViolet}}
        />
        <TextInput
          format={interval => subscriptionIntervalLabel[userMetrics.subscriptionInterval]}
          label={<nobr>bloomUp Abo Laufzeit</nobr>}
          helperText={false}
          inputProps={{className: classes.tintViolet}}
        />
        <TextInput
          source="createdAt"
          format={
            date => new Intl.DateTimeFormat('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }).format(userMetrics.subscriptionStartDate)}
          label="bloomUp Abo-Start"
          helperText={false}
          inputProps={{className: classes.tintViolet}}
        />
        <TextInput
          source="createdAt"
          format={
            date => new Intl.DateTimeFormat('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
          }).format(userMetrics.subscriptionEndDate)}
          label="bloomUp Abo-Ende"
          helperText={false}
          inputProps={{className: classes.tintViolet}}
        />
        <Box className={[classes.linkbox, classes.tintViolet]} style={{ height: '50px' }} ref={invoiceCell}>
          <Typography>Bloomer-Belege:{' '}
            {invoices && <Link disable onClick={() => loadInvoiceList()} variant="body2" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              {showInvoiceList ? 'verbergen' : 'anzeigen'}
            </Link>}
          </Typography>
          {showInvoiceList && <InvoiceList {...props} invoices={invoices} popupAtPosition={invoiceCell} />}
        </Box>
        <Box className={[classes.linkbox, classes.tintViolet]} >
          <Typography>bloomUp-Level:</Typography>
          <Badge badgeContent={userMetrics?.rewardLevel} color="primary" />
        </Box>
        <Box className={[classes.linkbox, classes.tintViolet]} >
          <Typography>bloomy Points:</Typography>
          <Badge badgeContent={userMetrics?.rewardPoints} max={99999} color="primary" />
        </Box>
        <TextInput source="referredBy" label="referredBy" inputProps={{className: classes.tintLightViolet}} />

        <Box className={[classes.linkbox, classes.tintLightViolet]}>
          <Typography gutterBottom>Eingeladen von:</Typography>
          <FormDataConsumer>
          {({ formData, ...rest }) => (
              <ReferenceField label="Referrer" source="referredBy" reference="users" link={false}>
                  <RouterLink to={`/users/${formData.referredBy}`} target="_blank" color="primary" style={{ textDecoration: 'none' }}>
                    <Typography color="primary">
                      <TextField source="name" /> {formData.referrer? (<TextField source="referrer" />) : ''}
                    </Typography>
                  </RouterLink>
              </ReferenceField>
            )
          }
           </FormDataConsumer>
        </Box>

        <SelectInput source="familySubscription" choices={[{ id: false, name: 'inaktiv' }, { id: true, name: 'aktiv' }]} label="Family Subscription Master" inputProps={{className: classes.tintLightViolet}} />
        <TextInput source="familyReferrer" label="Family referrer code" inputProps={{className: classes.tintLightViolet}} />
        <TextInput source="familyMemberOf" label="Family member of" inputProps={{className: classes.tintLightViolet}} />
        <Box className={[classes.linkbox, classes.tintLightViolet]} style={{ height: '50px' }} ref={familyCell}>
          <Typography gutterBottom>Family-Netzwerk:{' '}
            {familyNetwork && <Link disable onClick={() => loadFamilyNetworkList()} variant="body2" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              {showFamilyNetworkList ? 'verbergen' : 'anzeigen'}
            </Link>}
          </Typography>
          {showFamilyNetworkList && <UserList {...props} users={familyNetwork} popupAtPosition={familyCell} />}
        </Box>

        <TextInput source="referrer" label="Users chosen referrer code" inputProps={{className: classes.tintLightGreen}} />
        <Box className={[classes.linkbox, classes.tintLightGreen]}>
          <Typography gutterBottom>Empfehlungen:</Typography>
          <Badge badgeContent={userMetrics?.referredUsers} showZero max={99999} color="primary"  />
        </Box>
        <Box className={[classes.linkbox, classes.tintLightGreen]} style={{ height: '50px' }} ref={networkCell}>
          <Typography gutterBottom>Bloomer-Netzwerk:{' '}
            {bloomerNetwork && <Link disable onClick={() => loadBloomerNetworkList()} variant="body2" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              {showBloomerNetworkList ? 'verbergen' : 'anzeigen'}
            </Link>}
          </Typography>
          {showBloomerNetworkList && <UserList {...props} users={bloomerNetwork} popupAtPosition={networkCell} />}
        </Box>
        <Box className={[classes.linkbox, classes.tintLightGreen]} >
          <Typography>Bloomer-Netzwerk (Anzahl zahlender Bloomer):</Typography>
          <Badge badgeContent={userMetrics?.referredSubscribers} showZero max={99999} color="primary" />
        </Box>

        <Box className={[classes.radiobox, classes.tintGreen]}>
          {/*<Typography variant="caption">Influencer-Abrechnung:</Typography>*/}
          <BooleanInput source="isInfluencer" label="Influencer" />
        </Box>

        <Box className={[classes.radiobox, classes.tintGreen]}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const disableRateInput = !formData.isSuperInfluencer;
            return (
              <>
                <BooleanInput source="isSuperInfluencer" label="SUPER-Influencer" />
                <TextInput
                  source="superInfluencerRate"
                  type="number"
                  format={(val, before) => {
                    if(disableRateInput) return null;
                    return val ? Number(String(val).replace(/[^0-9.,]/g, '').replace(',', '.')).toFixed(2) : '';
                  }}
                  label={(disableRateInput ? false : 'SUPER-Influencer Friend-Tarif')}
                  inputProps={{ className: classes.tintGreen , min: 3, max: 3.5, step: 0.01 }}
                  style={{ width: '100%' }}
                  disabled={disableRateInput}
                  className={classes.inputWithCurrencySymbol}
                />
              </>
            );
          }}
        </FormDataConsumer>
        </Box>

        <TextInput source="influencerSupplierId" label="LJS-Lieferantenkennung" inputProps={{className: classes.tintGreen}} />

        {/*<SelectInput disabled={false} source="subscribed" choices={[{ id: false, name: 'false' }, { id: true, name: 'true' }]} />*/}

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const billingAddress = rest.record.addresses?.billing;
            return (
              <div style={{ float: 'right', width: '300px', position: 'absolute', top: '150px', left: '600px' }}>
                <Card className={classes.tintViolet}>
                  <CardContent>
                    <Typography variant="caption" pb={3}>Adressblock Belege</Typography>
                    <br />
                    <br />
                    <Typography>{billingAddress?.firstname} {billingAddress?.lastname}</Typography>
                    <Typography>{billingAddress?.address}</Typography>
                    <Typography>{billingAddress?.zipcode} {billingAddress?.city}</Typography>
                    <Typography>{countryNames[billingAddress?.country]}</Typography>
                  </CardContent>
                </Card>
              </div>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const shippingAddress = rest.record.addresses?.shipping ? rest.record.addresses?.shipping : rest.record.addresses?.billing;
            return (
              <div style={{ float: 'right', width: '300px', position: 'absolute', top: '150px', left: '950px' }}>
                <Card className={classes.tintViolet}>
                  <CardContent>
                    <Typography align="center" variant="caption" pb={3}>Adressblock Lieferadresse</Typography>
                    <br />
                    <br />
                    <Typography>{shippingAddress?.firstname} {shippingAddress?.lastname}</Typography>
                    <Typography>{shippingAddress?.address}</Typography>
                    <Typography>{shippingAddress?.zipcode} {shippingAddress?.city}</Typography>
                    <Typography>{countryNames[shippingAddress?.country]}</Typography>
                  </CardContent>
                </Card>
              </div>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const influencerData = formData.influencerData;

            return (
              <div style={{ float: 'right', width: '650px', position: 'absolute', top: '370px', left: '600px' }}>
                <Card className={classes.tintGreen}>
                  <CardContent>
                    <Typography variant="caption">Influencer-Daten:</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextInput source="influencerData.application_reason" label="Grund" helperText={false} fullWidth multiline className={classes.untint} />
                      </Grid>
                      <Grid item xs={5}>
                        <SelectInput source="influencerData.tax_reducible" choices={taxReducibleOptions} optionValue="item" optionText="label" label="Vorsteuerabzugsberechtigt" helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                      <Grid item xs={7}>
                        <TextInput source="influencerData.tax_id" label="Steuer-ID" helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="influencerData.account_owner" label="Kontoinhaber" helperText={false} className={classes.untint} />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="influencerData.account_iban" label="IBAN" helperText={false} className={classes.untint} />
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput source="influencerData.account_bic" label="BIC" helperText={false} className={classes.untint} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const role = rest.record.role;
            if(role !== 'watcher') return;

            const investmentData = formData.investmentData;

            return (
              <div style={{ float: 'right', width: '650px', position: 'absolute', top: '685px', left: '600px' }}>
                <Card className={classes.tintOrange}>
                  <CardContent>
                    <Typography variant="caption">Investment-Daten:</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <NumberInput source="investmentData.investment" label="Investment in €" min={500} helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                      <Grid item xs={3}>
                        <NumberInput source="investmentData.lots" label="Anzahl Lose" min={1} helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                      <Grid item xs={3}>
                        <NumberInput source="investmentData.share" label={<nobr>UB in %</nobr>} min={0.002} step={0.001} helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectInput source="influencerData.round" label="Invest-Runde" choices={investmentRoundOptions} optionValue="item" optionText="label" helperText={false} fullWidth className={classes.untint} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            );
          }}
        </FormDataConsumer>

        {/*<FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <div style={{ float: 'right', width: '400px', position: 'absolute', top: '150px', left: '1370px' }}>
                <Typography variant="caption">Userdata:</Typography>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell align="right">Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!userdataRows.length && <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>- keine vorhanden-</TableCell>
                      </TableRow>}
                      {userdataRows.length > 0 && userdataRows.map(userdata => (
                        <TableRow key={userdata.key}>
                          <TableCell component="th" scope="row">{userdata.key}</TableCell>
                          <TableCell align="right">{userdata.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          }}
        </FormDataConsumer>*/}

        <br />
        <br />
        <br />
        <br />

      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
