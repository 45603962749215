const authProvider = {
  // authentication
  login: ({ username, password }) => {
    const restUrl = process.env.REACT_APP_API_URL;
    const request = new Request(`${restUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    const adminEmailWhitelist = process.env.REACT_APP_ADMIN_WHITELIST.split(',');
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        const roleWhitelist = [
          'watcher',
          'employee',
          'admin',
          'superadmin',
        ];
        if(roleWhitelist.includes(auth.user.role)) {
        //if(adminEmailWhitelist.includes(auth.user.email)) {
          localStorage.setItem('auth', JSON.stringify(auth));
          localStorage.removeItem('permissions');
        }
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()),
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { user } = JSON.parse(localStorage.getItem('auth'));
      const { id, role, name: fullName, profilePictureHash: avatar } = user;
      return Promise.resolve({ id, role, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: async () => {
    const identity = await authProvider.getIdentity();

    const storedPermissions = localStorage.getItem('permissions');
    const storedRole = localStorage.getItem('role');

    if(storedRole === identity.role && storedPermissions) return Promise.resolve(JSON.parse(storedPermissions));

    const authJson = localStorage.getItem('auth');
    const { tokens } = JSON.parse(authJson);
    const jwtToken = tokens.access.token;
    const restUrl = process.env.REACT_APP_API_URL;

    const request = new Request(`${restUrl}/auth/permissions`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      }),
    });

    const authSettings = await fetch(request).then(res => res.json());

    const { role, permissions} = authSettings;

    localStorage.setItem('role', role);
    localStorage.setItem('permissions', JSON.stringify(permissions));

    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};

export default authProvider;
