import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Route } from 'react-router';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  DateInput,
  SelectInput,
  FunctionField,
  ReferenceField,
  Button,
} from 'react-admin';
import {
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const rowStyle = (record, index) => ({
  borderBottom: '1px solid lightgray',
});

const DetailDialog = (props) => {
  const { prices, names } = props.productInfo;

  if(!props.order) return null;

  const invoice = props.order;
  const product = invoice.cartContents[0];

  return (
    <Dialog
      fullWidth
      open={props.showDialog}
      onClose={props.onClose}
    >
      <DialogTitle>Bestellung</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small" aria-label="invoices">
              <TableHead>
                <TableRow>
                  <TableCell>Produkt</TableCell>
                  <TableCell>Preis</TableCell>
                  <TableCell>Versandkosten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={invoice.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {invoice.printId &&
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      {product.quantity} x Lebensbuch ({product.output} {product.format})
                    </Typography>
                    <Typography variant="caption">
                      {product.pages} Seiten
                    </Typography>
                  </TableCell>}

                  {!invoice.printId && <TableCell component="th" scope="row">
                    <Typography variant="body2">
                      {names[invoice.productId]}
                    </Typography>
                  </TableCell>}

                  <TableCell>
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                      invoice.amount ? invoice.amount : prices[invoice.productId]
                    )}
                  </TableCell>
                  <TableCell>
                    {invoice.shipping ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(invoice.shipping) : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={props.onClose}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const PaymentList = (props) => {
  const classes = useStyles();

  const [orderData, setOrderData] = useState(null);
  const [showOrderDetailDialog, setShowOrderDetailDialog] = useState(false);

  const handleClose = () => {
    props.push("/settings");
  };

  const invoiceFilters = [
    <DateInput label="Datum von" source="startDate" alwaysOn />,
    <DateInput label="Datum bis" source="endDate" alwaysOn />,
    <SelectInput label="Typ" source="type" choices={[
      { id: 'invoice', name: 'Rechnung' },
      { id: 'voucher', name: 'Gutschrift' },
    ]} alwaysOn />,
    <SelectInput label="Produkt" source="product" choices={[
      { id: 'subscriptions', name: 'Abo\'s / Lizenzen' },
      { id: 'prints', name: 'Print' },
      { id: 'merchandise', name: 'Merchandise' },
      { id: 'courses', name: 'Seminare' },
    ]} alwaysOn />,
    <TextInput label="Bloomer" source="user.name" alwaysOn />,
    <TextInput label="E-Mail" source="user.email" alwaysOn />,
  ];

  let productNames = {
    884: 'Family-Abo (jährliche Zahlung)',
    872: 'Friend-Abo (jährliche Zahlung)',
    477: 'Abo (jährliche Zahlung)',
    965: 'Friend-Abo (monatliche Zahlung)',
    103: 'Abo (monatliche Zahlung)',
    847: 'Print Hardcover 211x211',
    478: 'Print Softcover 211x211',
    886: 'Print Hardcover 210x297',
    888: 'Print Softcover 210x297',
    848: 'PDF',
  };

  const productPrices = {
    884: 95.40,
    872: 42.00,
    477: 47.52,
    965: 3.90,
    103: 4.45,
  };

  const handleOrderClick = (event, record) => {
    setOrderData(record)
    setShowOrderDetailDialog(true);
  };

  return (
    <React.Fragment>

      <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={invoiceFilters}>
        <Datagrid>
          <DateField source="createdAt" locales="de-DE" label="Datum" />
          
          <FunctionField label="Typ" source="productType" render={(record) => {
            return record.type === 'voucher' ? 'Gutschrift' : 'Rechnung';
          }} />

          <FunctionField label="Belegnummer" render={(record) =>
            record.orderId ? record.orderId : record.id.replace(/\D/g,'').slice(-8)
          } />

          <FunctionField label="Bestellung" render={(record) =>
            <a onClick={(event) => handleOrderClick(event, record)} href="javascript:void(0)">anzeigen</a>
          } />

          <FunctionField label="Beleg" render={(record) =>
            <a href={record.invoiceUrl} target="_blank">Download</a>
          } />

          <FunctionField label="Produkt" render={(record) => {
            return productNames[record.productId] ?? 'Unbekannt';
          }} />

          <TextField source="coupon" label="Gutscheincode" />

          <ReferenceField label="Bloomer" source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField label="E-Mail" source="userId" reference="users" link={false}>
            <TextField source="email" />
          </ReferenceField>

          {/*<TextField source="productId" />*/}
          {/*<TextField source="paymentId" label="Stripe Payment-ID" />*/}
          {/*<TextField source="amount" label="Betrag" />*/}
          {/*<TextField source="shipping" label="Versandkosten" />*/}
          {/*<TextField source="used" />*/}
          {/*<TextField source="paymentInterval" />*/}
          {/*<TextField source="refund" />*/}
          {/*<TextField source="pausedSince" />*/}
        </Datagrid>
      </List>

      <Route path="/invoice/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <SettingEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>

      <DetailDialog
        showDialog={showOrderDetailDialog}
        order={orderData}
        productInfo={{prices: productPrices, names: productNames}}
        onClose={() => setShowOrderDetailDialog(false)}
      />

    </React.Fragment>
  );
};

export default connect(
  undefined,
  { push }
)(PaymentList);