import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';

const SettingCreateToolbar = translate(({ onCancel, translate, ...props }) => (
   <Toolbar {...props}>
       <SaveButton />
       <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
   </Toolbar>
));

const SettingCreate = ({ onCancel, ...props }) => (
    <Create title=" " {...props}>
       <SimpleForm toolbar={<SettingCreateToolbar onCancel={onCancel} />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="value" validate={required()} />
        </SimpleForm>
    </Create>
);

export default SettingCreate;