import React, { useContext } from 'react';
import { VariantContext } from '../hooks/VariantContext';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { Field, Form } from 'react-final-form';
import {
  CssBaseline,
  Container,
  Typography,
  Avatar,
  CircularProgress,
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '60em',
    },
    paper: {
      marginTop: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }),
  { name: 'RaLogin' }
);

const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
  <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

const LoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const { appVariant } = useContext(VariantContext);

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
          }
        );
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {appVariant === 'bloomup' && (<Avatar src="/images/login-symbol.png"></Avatar>)}
        {appVariant === 'ljs' && (<Avatar src="/images/login-symbol-ljs.png"></Avatar>)}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="email"
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={'E-Mail'}
                disabled={loading}
              />
              <Field
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                autoComplete="current-password"
              />
              <Button
                fullWidth
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.submit}
              >
                {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                {translate('ra.auth.sign_in')}
              </Button>
            </form>
          )}
        />
      </div>
    </Container>
  );
};

export default LoginForm;
