import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, makeStyles } from '@material-ui/core';
import {
  useLocale,
  List,
  EditButton,
  DeleteButton,
  Datagrid,
  CardActions,
  CreateButton,
  FunctionField,
  TextField,
} from 'react-admin';
import { Route } from 'react-router';
import AppcodeCreate from './AppcodeCreate';
import AppcodeEdit from './AppcodeEdit';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const AppcodeListActions = ({ basePath }) => (
   <CardActions>
       <CreateButton basePath={basePath} />
   </CardActions>
);

const AppcodeList = props => {
  const locale = useLocale();
  const classes = useStyles();

  const handleClose = () => {
    props.push("/appcodes");
  };

  return (
    <React.Fragment>
      <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} actions={<AppcodeListActions />}>
        <Datagrid>
          <TextField source="name" />
          <TextField source="code" />
          <FunctionField label="Valid from" render={record => record.validFrom ? new Date(record.validFrom).toLocaleDateString(locale) : '-'} />
          <FunctionField label="Valid to" render={record => record.validTo ? new Date(record.validTo).toLocaleDateString(locale) : '-'} />
          <FunctionField label="Invite-Link" style={{ fontSize: '10px' }} render={record => {
            const appRedirectUrl = `${process.env.REACT_APP_FIREBASE_APP_URL}`;
            const params = { referrer: record.code };
            const referrerParams = encodeURIComponent(JSON.stringify(params));
            const urlParams = encodeURIComponent(`https://app.getbloomup.com/login?referrer=${referrerParams}`);
            const linkParam = `&link=${urlParams}`;
            const fallbackUrl = `https://my.getbloomup.com/signup?referrer=${referrerParams}`;
            const fallbackParams = `&afl=${fallbackUrl}&ifl${fallbackUrl}=&ofl=${fallbackUrl}`;

            return `${appRedirectUrl}${linkParam}${fallbackParams}`;
          }} />
          <EditButton basePath="/appcodes" />
          <DeleteButton basePath="/appcodes" />
        </Datagrid>
      </List>

      <Route path="/appcodes/create">
        {({ match }) => {
          //console.log('CREATE?')
          return;
          return (
            <Drawer open={!!match} anchor="right" onClose={handleClose}>
              <AppcodeCreate onCancel={handleClose} className={classes.drawerContent} {...props} />
            </Drawer>
          )
        }}
      </Route>
      <Route path="/appcodes/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <AppcodeEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { push }
)(AppcodeList);


