const ljsTheme = {
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      light: '#9A92DF',
      main: '#312783',
      dark: '#5E50CB',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5E50CB',
    },
    text: {
      primary: 'rgba(50, 50, 50, 1)',
      secondary: 'rgba(100, 100, 120, 1)',
    },
  },
  shape: {
    borderRadius: 10,
  },
};

export default ljsTheme;
