const bloomupTheme = {
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      light: '#9353A1',
      main: '#981869',
      dark: '#520D39',
      contrastText: '#fff',
    },
    secondary: {
      main: '#981869',
    },
    text: {
      primary: 'rgba(50, 50, 50, 1)',
      secondary: 'rgba(100, 100, 120, 1)',
    },
  },
  shape: {
    borderRadius: 10,
  },
};

export default bloomupTheme;
