import React from 'react';
import {
  Edit,
  TextInput,
  NumberInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { useLocale } from 'react-admin'

const GamificationEditToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button m={5} onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const GamificationEdit = ({ onCancel, ...props }) => {
  const locale = useLocale()

  return (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<GamificationEditToolbar onCancel={onCancel} />}>
      <TextInput source="id" disabled />
      <TextInput source="group" validate={required()} />
      <TextInput source="name" disabled validate={required()} />
      <TextInput multiline source="description" />
      <NumberInput source="value" validate={required()} min={0} />
    </SimpleForm>
  </Edit>
)};

export default GamificationEdit;
