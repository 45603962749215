import React from 'react';
import {
  Create,
  SelectInput,
  TextInput,
  BooleanInput,
  DateInput,
  NumberInput,
  FormDataConsumer,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  TopToolbar,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DeeplinkIcon from '@material-ui/icons/OpenInNew';

const NotificationCreateActions = ({ basePath }) => (
  <TopToolbar>
    <Button
      variant="outlined"
      startIcon={<DeeplinkIcon />}
      size="small"
      style={{ marginRight: 50 }}
      onClick={() => { window.open(`https://github.com/kodira/bloomup/wiki/BloomUp-Spec-(WIP)#deep-links`, '_blank') }}
    >
      Deep-Links
    </Button>
  </TopToolbar>
);

const NotificationCreateToolbar = translate(({ onCancel, translate, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
  </Toolbar>
));

const notificationCategories = [
  { item: 'PROMOTION', label: 'Promotions' },
  { item: 'NOTIFICATION', label: 'Benachrichtigungen' },
  { item: 'EVALUATION', label: 'Testzeitraum' },
  { item: 'GAMIFICATION', label: 'Gamification' },
];

const NotificationCreate = ({ onCancel, ...props }) => (
  <Create title=" " {...props} actions={<NotificationCreateActions />}>
    <SimpleForm redirect="list" toolbar={<NotificationCreateToolbar onCancel={onCancel} />}>
      <SelectInput source="category" choices={notificationCategories} defaultValue={'quote'} optionValue="item" optionText="label" />
      <TextInput source="title" validate={required()} />
      <TextInput source="subTitle" />
      <TextInput source="body" />
      <TextInput source="url" />
      <BooleanInput source="notify" label="Notify (als Smartphone Notification außerhalb der App anzeigen)" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          let inputs;

          switch(formData.category) {
            case 'EVALUATION':
              inputs = <>
                <NumberInput
                  label="Tageszahl"
                  source="validFrom"
                  parse={val => new Date(`2000-01-${Math.max(Math.min(val, 30), 1)}`)}
                  format={val => (new Date(val)).getDate() }
                />
                <BooleanInput source="showAfterEvaluationPeriod" />
              </>;
              break;
              case 'GAMIFICATION':
                inputs = <>
                  <NumberInput label="Punkteschwelle" source="pointThreshold" />
                  <TextInput fullWidth multiline rows={20} label="Mail-Inhalt" source="mailContent" />
                </>;
                break;
              default:
                inputs = <>
                  <DateInput source="validFrom" />
                  <DateInput source="validUntil" />
                </>
                break;
          }

          return inputs;
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default NotificationCreate;