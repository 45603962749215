import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, makeStyles } from '@material-ui/core';
import {
  List,
  useListContext,
  SimpleList,
  CardActions,
  CreateButton,
} from 'react-admin';
import { Route } from 'react-router';
import SettingCreate from './SettingCreate';
import SettingEdit from './SettingEdit';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const rowStyle = (record, index) => ({
  borderBottom: '1px solid lightgray',
});


const SettingListActions = ({ basePath }) => (
   <CardActions>
       {/*<CreateButton basePath={basePath} />*/}
   </CardActions>
);

const SettingList = props => {
  const classes = useStyles();
  const listContext = useListContext();

  useEffect(() => {
    // disables pagination and shows all results on one page
    if (listContext.perPage < listContext.total) {
      listContext.setPerPage(listContext.total)
    }
  }, [listContext])

  const handleClose = () => {
    props.push("/settings");
  };

  return (
    <React.Fragment>
      <List basePath="/settings" resource="settings" pagination={false}>
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => `${record.value}`}
          tertiaryText={record => `stage`}
          linkType={record => `edit`}
          rowStyle={rowStyle}
        />
      </List>
      <Route path="/settings/create">
        {({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <SettingCreate onCancel={handleClose} className={classes.drawerContent} {...props} />
          </Drawer>
        )}
      </Route>
      <Route path="/settings/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose} >
              {isMatch ? (
                <SettingEdit
                  id={match.params.id}
                  onCancel={handleClose}
                  className={classes.drawerContent}
                  {...props}
                />
              ) : (
                  <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { push }
)(SettingList);