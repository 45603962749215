import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const UserCreate = (props) => {
  return (
    <Create title="Create user" {...props}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="appcode" />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
